import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  Avatar,
  ListItemAvatar,
  Stack,
  Container,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import useApi from "../hooks/useApi";

function GlobalHeader() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { loggedApi } = useApi();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  var opciones = [];

  if (!!loggedApi) {
    opciones = [
      {
        title: "FACTURAS",
        route: "/facturas",
        icon: LibraryBooksIcon,
      },
      {
        title: "ALBARANES",
        route: "/albaranes",
        icon: ShareLocationIcon,
      },
      {
        title: "MODELO 347",
        route: "/modelo347",
        icon: FindInPageIcon,
      },
      {
        title: "SALIR",
        route: "/logout",
        icon: LogoutIcon,
      },
    ];
  }

  return (
    <>
      <AppBar id="GlobalHeader" position="static">
        <Container
          sx={{
            paddingLeft: "0!important",
            paddingRight: "0!important",
            py: 1,
          }}
        >
          <Toolbar>
            {!!loggedApi && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  display: {
                    xs: "inherit",
                    lg: "none",
                  },
                }}
                onClick={toggleDrawer(true)}
              >
                <Menu />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <img src="/logo.gif" width="70px" />
            </Box>
            <Stack
              direction="row"
              sx={{
                display: {
                  xs: "none",
                  lg: "inherit",
                },
              }}
              spacing={3}
            >
              {opciones.map((row, key) => (
                <Box
                  key={key}
                  component={Link}
                  to={row.route}
                  display="flex"
                  alignItems="center"
                  gap="3px"
                  sx={{
                    cursor:
                      window.location.pathname === row.route
                        ? "default"
                        : "pointer",
                    borderBottom: "2px solid",
                    transition: "border-color 0.2s ease-in-out",
                    pb: 1,
                    borderColor:
                      window.location.pathname === row.route
                        ? "#fff"
                        : "secondary.main",
                    ":hover": {
                      borderColor: "white",
                    },
                  }}
                >
                  <row.icon sx={{ color: "#fff" }} />
                  <Typography>{row.title}</Typography>
                </Box>
              ))}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box bgcolor="black" px={2} height="100vh" overflow="hidden">
            <List sx={{ height: "100%", p: 0, pt: 2 }}>
              <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                <Box>
                  <ListItem sx={{ my: 1 }}>
                    <ListItemAvatar>
                      <Avatar
                        src="/logo.gif"
                        sx={{
                          width: "180px",
                          height: "auto",
                          borderRadius: 0,
                        }}
                      />
                    </ListItemAvatar>
                  </ListItem>
                  {opciones.map((item, i) => (
                    <Link key={i} to={item.route}>
                      <ListItem>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{
                            borderBottom: "3px solid",
                            borderColor:
                              window.location.pathname === item.route
                                ? "#fff"
                                : "secondary.main",
                            width: "100%",
                            py: 1,
                          }}
                        >
                          <item.icon sx={{ color: "#fff" }} />
                          <Typography color="white">{item.title}</Typography>
                        </Stack>
                      </ListItem>
                    </Link>
                  ))}
                </Box>
              </Stack>
            </List>
          </Box>
        </div>
      </Drawer>
    </>
  );
}

export default GlobalHeader;
