import {
  Alert,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../hooks/useUser";
import Header from "../../components/header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";

const Login = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const { loadApi, loadingApi } = useApi();
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [recordarContrasena, setRecordarContrasena] = useState(false);
  const [cif, setCIF] = useState(false);

  function findMatch(inputString) {
    const regexPatterns = [
      /.* - ([A-Z0-9]+)$/,
      /([A-Z0-9]+) .* \(R: ([A-Z0-9]+)\)/
    ];

    for (let regex of regexPatterns) {
      const match = inputString.match(regex);
      if (match) {
        return match[match.length - 1];
      }
    }
    return false;
  }

  //SSL LOGIN FUNCTION
  async function loginSsl() {
    setCIF(true);
    setError(false);
    const config = {
      url: `https://tomas.ccorbit.com/ssl.php`,
    };

    var response1 = false;
    try {
      response1 = await axios(config);
    } catch (e) { }


    var cifdata = "";
    try {
      cifdata = response1.data.find((item) => item.startsWith("CN="));
      cifdata = cifdata.replace("CN=", "");
      if (!cifdata) {
        setError("No tienes ningún certificado instalado.");
        setCIF(false);
        return;
      }
      cifdata = findMatch(cifdata);
      if (!cifdata) {
        setError("El certificado que seleccionaste no tiene un usuario existente.");
        setCIF(false);
        return;
      }
    } catch (e) {
      setError("No has seleccionado ningún certificado o no lo tienes instalado.");
      setCIF(false);
      return;
    }

    if (!cifdata || cifdata === "") {
      setCIF(false);
      return setError("Certificado no válido.");
    }

    // cifdata = "39467459B";
    const response2 = await loadApi(`ifwsevcl?CIF=${cifdata}&OPERACION=3`, false, "get");
    if (!response2?.CLIENTES?.ACCESO?.EMAIL || !response2?.CLIENTES?.ACCESO?.PASWOR) { setCIF(false); return setError("Certificado no válido"); }

    loadApi(
      `ifwsevcl?EMAIL=${response2?.CLIENTES?.ACCESO?.EMAIL}&PASWOR=${response2?.CLIENTES?.ACCESO?.PASWOR}&OPERACION=1`,
      false,
      "get"
    )
      .then((response) => {
        if (
          !!response?.CLIENTES?.ACCESO &&
          !!response?.CLIENTES?.CLIENTE?.CIF
        ) {
          localStorage.setItem("token", response.CLIENTES.CLIENTE.CIF);
          setUser(response.CLIENTES.CLIENTE);
          navigate("/");
        } else {
          setError("Usuario o Contraseña incorrecto.");
        }
      })
      .catch((e) => { });

    setCIF(false);
  }


  //LOGIN FUNCTION
  function handleSignin(e) {
    e.preventDefault();
    setError(false);
    setForm((data) => {
      if (data.username) data["username"] = data.username.toLowerCase();
      return data;
    });

    if (!form?.username || !form?.password) {
      setError("Debes completar todos los campos.");
      return;
    }

    if (!recordarContrasena) {
      setError("Debes aceptar los términos.");
      return;
    }

    loadApi(
      `ifwsevcl?EMAIL=${form.username}&PASWOR=${form.password}&OPERACION=1`,
      false,
      "get"
    )
      .then((response) => {
        if (
          !!response?.CLIENTES?.ACCESO &&
          !!response?.CLIENTES?.CLIENTE?.CIF
        ) {
          localStorage.setItem("token", response.CLIENTES.CLIENTE.CIF);
          setUser(response.CLIENTES.CLIENTE);
          navigate("/");
        } else {
          setError("Usuario o Contraseña incorrecto.");
        }
      })
      .catch((e) => { });
  }

  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Header />
      <Container
        maxWidth="lg"
        sx={{
          py: 3,
          minHeight: "calc(100vh - 103px - 48px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} lg={6}>
            <Box
              component="form"
              role="form"
              onSubmit={handleSignin}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                spacing={2}
                width="100%"
                alignItems="center"
                sx={{ backgroundColor: "white", py: 5, px: 4 }}
              >
                <Typography variant="h3" color="secondary">
                  Soy Cliente
                </Typography>
                <Typography variant="body1" color="GrayText" fontWeight={400}>
                  Introduce tus datos para ingresar en tu área de cliente.
                </Typography>
                {!!error && (
                  <Alert sx={{ width: "92%" }} severity="error">
                    {error}
                  </Alert>
                )}
                <TextField
                  color="secondary"
                  variant="standard"
                  fullWidth
                  label="Usuario"
                  value={form.username}
                  onChange={(e) => setForm({ ...form, username: e.target.value })}
                  name="email"
                  type="email"
                />
                <TextField
                  fullWidth
                  color="secondary"
                  variant="standard"
                  label="Contraseña"
                  onChange={(e) => setForm({ ...form, password: e.target.value })}
                  value={form.password}
                  name="password"
                  type="password"
                  id="password"
                />
                <Stack alignItems="start" width="100%">
                  <Typography fontSize="1rem" fontWeight={500}>
                    Tratamiento de datos de carácter personal.
                  </Typography>
                  <Typography fontWeight={400} mt={1} fontSize="0.75rem">
                    El responsable del Tratamiento es Tomás Fernández Vigo, S.L con
                    la finalidad de proceder a su registro como usuario de la web.
                    Puede acceder, rectificar y suprimir sus datos y ejercer otros
                    derechos según consta en la información adicional de protección
                    de datos que puede consultar pinchando aquí.
                  </Typography>
                </Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recordarContrasena}
                      onChange={() => setRecordarContrasena(!recordarContrasena)}
                    />
                  }
                  label={
                    <Typography fontWeight={400} fontSize="0.8rem" color="gray">
                      He leído y acepto el tratamiento de mis datos en los términos
                      señalados en la cláusula adicional sobre protección de datos.
                    </Typography>
                  }
                />
                <LoadingButton
                  loading={loadingApi.includes(
                    `get__ifwsevcl?EMAIL=${form.username}&PASWOR=${form.password}&OPERACION=1`
                  )}
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Iniciar Sesión
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                height: "100%",
                position: "relative"
              }}
            >
              <Stack
                spacing={5}
                width="100%"
                alignItems="center"
                sx={{ py: 5, px: 4 }}
              >
                <Typography variant="h3" color="secondary" align="center" lineHeight={1}>
                  Acceso con certificado digital
                </Typography>
                <img src="/certificate.png" width="50%"></img>
                <LoadingButton
                  loading={cif}
                  size="large"
                  variant="contained"
                  onClick={loginSsl}
                  startIcon={<LockOutlinedIcon />}
                >
                  Entrar con certificado
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>

      </Container>
    </>
  );
};

export default Login;
