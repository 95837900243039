import {
  Alert,
  Button,
  Container,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../../components/header";
import { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { useState } from "react";
import Table from "./table";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import isObject from "../../../utils/isObject";

export default function Facturas() {
  const { loadApi, loadingApi } = useApi();
  const [maindata, setMainData] = useState(false);
  const [apiDate, setApiDate] = useState({
    desde: false,
    hasta: false,
  });
  const [inputDate, setInputDate] = useState({
    desde: "2023-01-01",
    hasta: "",
  });
  const { idcliente, tipofactura } = useParams();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  var codcliente = idcliente;
  if (codcliente.length < 6) codcliente = "0" + codcliente;

  useEffect(() => {
    setError(false);
    setMainData(false);
    loadApi(
      `ifwselfr?OPERACION=1${!!apiDate?.desde && `&DESDE_FECHA=${apiDate.desde}`
      }&EMPRESA=${tipofactura}&COD_CLIENTE=${codcliente}${!!apiDate?.hasta && `&HASTA_FECHA=${apiDate.hasta}`
      }`,
      false,
      "get"
    )
      .then((response) => {
        if (!Array.isArray(response?.FACTURAS?.FACTURA)) {
          if (!!response?.FACTURAS?.CONTROL?.FLAG) {
            if (response.FACTURAS.CONTROL.FLAG !== "0.- Operacion Correcta") {
              setError(response.FACTURAS.CONTROL.FLAG);
            }
          } else {
            setError("Error al obtener la petición del servidor.");
          }
        }
        if (!!Array.isArray(response?.FACTURAS?.FACTURA)) {
          orderArray(response.FACTURAS.FACTURA)
        } else if (isObject(response.FACTURAS.FACTURA)) {
          orderArray([response.FACTURAS.FACTURA]);
        } else {
          setError("La estructura de la API ha cambiado.");
        }
      })
      .catch((e) => {
        setError("Error al obtener la petición del servidor.");
      });
  }, [tipofactura, apiDate]);

  function orderArray(ArrayActual) {
    const sortedArray = ArrayActual.sort((a, b) => {
      const dateA = convertirFecha(a.FECHA_FRA);
      const dateB = convertirFecha(b.FECHA_FRA);
      return dateB - dateA; // Ordena de más reciente a más antiguo
    });

    setMainData(sortedArray);
  }

  function convertirFecha(fechaStr) {
    const parts = fechaStr.split('-');
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  const convertToPDF = (filedata, name) => {
    const base64Data = "data:application/pdf;base64," + filedata;
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name + ".pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  function descargarPDF(nfactura) {
    loadApi(
      `ifwsefrp?CIF_CLIENTE=${localStorage.getItem(
        "token"
      )}&OPERACION=1&EMPRESA=${tipofactura}&FACTURA=${nfactura}`,
      false,
      "get"
    )
      .then((response) => {
        if (!!response?.FRAPDF?.FileData)
          convertToPDF(response?.FRAPDF?.FileData, "Factura" + nfactura);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const handleChange = (event) => {
    navigate(`/facturas/cliente/${idcliente}/${event.target.value}`);
  };

  function changeDate() {
    setApiDate({
      desde: inputDate.desde.replaceAll("-", ""),
      hasta: inputDate.hasta.replaceAll("-", ""),
    });
  }

  return (
    <Stack>
      <Header></Header>
      <Container sx={{ mt: 2, py: 2, backgroundColor: "#fff" }}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography variant="h2" color="secondary">
              Facturas
            </Typography>
            <Button
              LinkComponent={Link}
              to="/facturas"
              sx={{ height: "fit-content" }}
              startIcon={<ArrowBackIcon />}
            >
              Volver
            </Button>
          </Stack>
          <Stack>
            <Typography variant="subtitle1" fontWeight={400} color="GrayText">
              En esta sección se muestran la lista de facturas por empresa y por
              filtro que usted elija.
            </Typography>
          </Stack>
          <Divider></Divider>
          <Typography variant="h5" color="GrayText">
            Buscar:
          </Typography>
          <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
            <Stack direction="row" spacing={3} alignItems="end" flexWrap="wrap">
              <Stack>
                <Typography variant="body1" color="gray">
                  Fecha inicio
                </Typography>
                <TextField
                  type="date"
                  value={inputDate.desde}
                  onChange={({ target }) =>
                    setInputDate((adata) => ({ ...adata, desde: target.value }))
                  }
                />
              </Stack>
              <Stack>
                <Typography variant="body1" color="gray">
                  Fecha fin
                </Typography>
                <TextField
                  type="date"
                  value={inputDate.hasta}
                  onChange={({ target }) =>
                    setInputDate((adata) => ({ ...adata, hasta: target.value }))
                  }
                />
              </Stack>
              <Button
                sx={{ height: "56px", borderRadius: "3px" }}
                startIcon={<SearchIcon />}
                onClick={() => changeDate()}
              >
                Buscar fecha
              </Button>
            </Stack>
            <Stack>
              <Typography variant="body1" color="gray">
                Cambiar Empresa:
              </Typography>
              <Select value={tipofactura} onChange={handleChange}>
                <MenuItem value="T">Tomás Fernández</MenuItem>
                <MenuItem value="P">Primeras Marcas</MenuItem>
              </Select>
            </Stack>
          </Stack>
          <Typography variant="h5" color="GrayText">
            Lista de facturas
          </Typography>
          {!!maindata ? (
            <Table
              maindata={maindata}
              descargarPDF={descargarPDF}
              loadingApi={loadingApi}
              tipofactura={tipofactura}
            />
          ) : !error ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <Alert severity="error">
              {error.match(/^\d+\.\-\s*(.+)/)?.[1] ||
                "Error al obtener la petición del servidor."}
            </Alert>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
