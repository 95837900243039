import { Alert, Container, Divider, Stack, Typography } from "@mui/material";
import Header from "../../components/header";
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import { useState } from "react";
import Table from "./table";
import CircularProgress from "@mui/material/CircularProgress";
import isObject from "../../utils/isObject";

export default function Facturas() {
  const { loadApi } = useApi();
  const [maindata, setMainData] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadApi(
      `ifwsecfr?CIF_CLIENTE=${localStorage.getItem("token")}&OPERACION=1`,
      false,
      "get"
    )
      .then((response) => {
        if (!Array.isArray(response?.FACTURAS?.FACTURA)) {
          if (!!response?.FACTURAS?.CONTROL?.FLAG) {
            if (response.FACTURAS.CONTROL.FLAG !== "0.- Operacion Correcta") {
              setError(response.FACTURAS.CONTROL.FLAG);
            }
          } else {
            setError("Error al obtener la petición del servidor.");
          }
        }
        if (!!Array.isArray(response?.FACTURAS?.FACTURA)) {
          setMainData(response.FACTURAS.FACTURA)
        } else if (isObject(response.FACTURAS.FACTURA)) {
          setMainData([response.FACTURAS.FACTURA]);
        } else {
          setError("La estructura de la API ha cambiado.");
        }
      })
      .catch((e) => {
        setError("Error al obtener la petición del servidor.");
      });
  }, []);

  return (
    <Stack>
      <Header></Header>
      <Container sx={{ mt: 2, py: 2, backgroundColor: "#fff" }}>
        <Stack spacing={2}>
          <Typography variant="h2" color="secondary">
            Albaranes
          </Typography>
          <Stack>
            <Typography variant="subtitle1" fontWeight={400} color="GrayText">
              En esta sección se muestran todas los albaranes asociados a los
              diferentes CIF que usted maneja.
            </Typography>
            <Typography variant="subtitle1" fontWeight={600} color="GrayText">
              Se mostrarán ordenadas por el número de cliente
            </Typography>
          </Stack>
          <Divider></Divider>
          <Typography variant="h5" color="GrayText">
            Lista de clientes
          </Typography>
          {!!maindata ? (
            <Table maindata={maindata} />
          ) : !error ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <Alert severity="error">
              {error.match(/^\d+\.\-\s*(.+)/)?.[1] ||
                "Error al obtener la petición del servidor."}
            </Alert>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
