import {
  Alert,
  Container,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../components/header";
import useApi from "../../hooks/useApi";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";

export default function Facturas() {
  const { loadApi, loadingApi } = useApi();
  const [empresa, setEmpresa] = useState("T");
  const [inputDate, setInputDate] = useState({
    year: "2023",
    importe: "0"
  });
  const [error, setError] = useState(false);


  function descargarPDF() {
    loadApi(
      `ifwse347?CIF_CLIENTE=${localStorage.getItem(
        "token"
      )}&OPERACION=1&IMPORTE_MIN=${inputDate.importe}&EMPRESA=${empresa}&ANO=${inputDate.year}`,
      false,
      "get"
    )
      .then((response) => {
        if (!Array.isArray(response?.MOD347?.FACTURA)) {
          if (!!response?.MOD347?.CONTROL?.FLAG) {
            if (response.MOD347.CONTROL.FLAG !== "0.- Operacion Correcta") {
              setError(response.MOD347.CONTROL.FLAG);
            }
          } else {
            setError("Error al obtener la petición del servidor.");
          }
        }

        if (!!response?.MOD347?.FileData) {
          convertToPDF(response?.MOD347?.FileData, "Modelo347");
        }
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      });
  }


  const convertToPDF = (filedata, name) => {
    const base64Data = "data:application/pdf;base64," + filedata;
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name + ".pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };


  return (
    <Stack>
      <Header></Header>
      <Container sx={{ mt: 2, py: 2, backgroundColor: "#fff" }}>
        <Stack spacing={2}>
          <Typography variant="h2" color="secondary">
            Modelo 347
          </Typography>
          <Divider></Divider>
          <Typography variant="h5" color="GrayText">
            Buscar:
          </Typography>
          {!!error && <Alert severity="error">
            {error.match(/^\d+[\.]?\-\s*(.+)/)?.[1] ||
              "Error al obtener la petición del servidor."}
          </Alert>}

          <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
            <Stack direction="row" spacing={3} alignItems="end" flexWrap="wrap">
              <Stack>
                <Typography variant="body1" color="gray">
                  Año
                </Typography>
                <TextField
                  type="number"
                  value={inputDate.year}
                  onChange={({ target }) =>
                    setInputDate((adata) => ({ ...adata, year: target.value }))
                  }
                />
              </Stack>
              <Stack>
                <Typography variant="body1" color="gray">
                  Importe mínimo
                </Typography>
                <TextField
                  type="number"
                  value={inputDate.importe}
                  onChange={({ target }) =>
                    setInputDate((adata) => ({ ...adata, importe: target.value }))
                  }
                />
              </Stack>
              <LoadingButton
                loading={loadingApi.includes(
                  `get__ifwse347?CIF_CLIENTE=${localStorage.getItem(
                    "token"
                  )}&OPERACION=1&IMPORTE_MIN=${inputDate.importe}&EMPRESA=${empresa}&ANO=${inputDate.year}`
                )}
                onClick={() => descargarPDF()}
                color="secondary"
                startIcon={<DownloadIcon />}
                sx={{ height: "56px", borderRadius: "3px" }}
              >
                Descargar modelo
              </LoadingButton>
            </Stack>
            <Stack>
              <Typography variant="body1" color="gray">
                Empresa:
              </Typography>
              <Select value={empresa} onChange={({ target }) => setEmpresa(target.value)}>
                <MenuItem value="T">Tomás Fernández</MenuItem>
                <MenuItem value="P">Primeras Marcas</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
