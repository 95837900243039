// Pages
import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";
import Facturas from "./pages/facturas";
import FacturasC from "./pages/facturas/cliente";
import Albaranes from "./pages/albaranes";
import AlbaranesC from "./pages/albaranes/cliente";
import Modelo347 from "./pages/modelo347";

const routes = [
  {
    route: "/login",
    role: "auth",
    component: <Login />,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
  //--------------DASHBOARD ROUTES----------------
  {
    route: "/modelo347",
    role: "logged",
    component: <Modelo347 />,
  },
  {
    route: "/facturas",
    role: "logged",
    component: <Facturas />,
  },
  {
    route: "/facturas/cliente/:idcliente/:tipofactura",
    role: "logged",
    component: <FacturasC />,
  },
  {
    route: "/albaranes",
    role: "logged",
    component: <Albaranes />,
  },
  {
    route: "/albaranes/cliente/:idcliente/:tipofactura",
    role: "logged",
    component: <AlbaranesC />,
  },
];

export default routes;
