import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    backgroundColor: "#ddd",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function TableView({ maindata }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>(CODIGO) NOMBRE DE CLIENTE</StyledTableCell>
            <StyledTableCell align="right">
              ALBARANES TOMAS FERNANDEZ
            </StyledTableCell>
            <StyledTableCell align="right">
              ALBARANES PRIMERAS MARCAS
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {maindata.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                ({row.COD_CLIENTE}) {row.NOM_COMERC}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.FACTURAS_TF === "N" ? (
                  <Button
                    disabled
                    color="secondary"
                    startIcon={<DoDisturbIcon />}
                    sx={{ borderRadius: "30px", fontSize: "1rem" }}
                  >
                    No disponible
                  </Button>
                ) : (
                  <Button
                    LinkComponent={Link}
                    to={`/albaranes/cliente/${row.COD_CLIENTE}/T`}
                    color="secondary"
                    startIcon={<ShareLocationIcon />}
                    sx={{ borderRadius: "30px", fontSize: "1rem" }}
                  >
                    Ver Albaranes
                  </Button>
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.FACTURAS_PM === "N" ? (
                  <Button
                    disabled
                    color="secondary"
                    startIcon={<DoDisturbIcon />}
                    sx={{ borderRadius: "30px", fontSize: "1rem" }}
                  >
                    No disponible
                  </Button>
                ) : (
                  <Button
                    LinkComponent={Link}
                    to={`/albaranes/cliente/${row.COD_CLIENTE}/P`}
                    color="secondary"
                    startIcon={<ShareLocationIcon />}
                    sx={{ borderRadius: "30px", fontSize: "1rem" }}
                  >
                    Ver Albaranes
                  </Button>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
